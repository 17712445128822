const universityPrograms = {
    "usc": [
        {"value": "General_llm", "label": "General LLM"},
        {"value": "Two_year_llm", "label": "Two-Year LLM"},
        {"value": "ADR", "label": "LLM in Alternative Dispute Resolution"},
        {"value": "business_law", "label": "LLM in Business Law"},
        {"value": "IBEL", "label": "LLM in International Business and Economic Law (LLM in IBEL)"},
        {"value": "MAEL", "label": "LLM in Media and Entertainment Law"},
        {"value": "PLCS", "label": "LLM in Privacy Law and Cybersecurity"},
        {"value": "Online_llm", "label": "Online LLM"},
        {"value": "MCL", "label": "Master of Comparative Law"},
        {"value": "MSL_online", "label": "Master of Studies in Law (MSL) - Online"},
        {"value": "MSL_onCampus", "label": "Master of Studies in Law (MSL) - On Campus"},
        {"value": "MDR", "label": "Master of Dispute Resolution"},
        {"value": "MITLE", "label": "Master of International Trade Law and Economics"},
        {"value": "MIELR", "label": "Master of Science in Innovation Economics, Law and Regulation"},
    ],
    "Dickinson": [
        {"value": "carlisle", "label": "Carlisle"},
        {"value": "university_park", "label": "University Park"},
    ],
    "gateway": [
        {"value": "advertising", "label": "Advertising with Gateway"},
        {"value": "candidate_advocacy", "label": "Finding jobs in higher-ed"},
        {"value": "executive_search", "label": "Hiring higher-ed executives"},
        {"value": "global_scope_360", "label": "An external review via GlobalScope 360"},
        {"value": "on_demand_staffing", "label": "Hiring on-demand international education staff"},
        {"value": "IPAC", "label": "International partnerships and collaborations"},
    ],
    "esigelec": [
        {"value": "master_of_technology", "label": "Master of Technology"}
    ],
    "GW Law": [
        {"value": "general_llm", "label": "General LLM"}
    ],
    "SMU": [
        {"value": "foreign_llm", "label": "LL.M. for Foreign Law School Graduates"}
    ],
    "Texas": [
        {"value": "general_llm", "label": "On-Campus LLM - General Studies"}
    ],
    "georgetown": [
        {"value": "general_llm", "label": "General/International Legal Studies"}
    ],
    "ucla": [
        {"value": "entertainment", "label": "LLM in Media, Entertainment, and Technology Law and Policy"},
        {"value": "international", "label": "LLM in International and Comparative Law"},
        {"value": "human_rights", "label": "LLM in Human Rights"},
    ],
    "boston": [
        {"value": "american_law_program", "label": "LLM in American Law"},
        {"value": "banking_law_program", "label": "LLM in Banking and Financial Law"},
        {"value": "intellectual_property", "label": "LLM in Intellectual Property & Information Law"},
        {"value": "texation_program", "label": "LLM in Taxation"},
        {"value": "two_year_llm_program", "label": "Two-Year LLM Program"},
        {"value": "msl_tax_program", "label": "Master in the Study of Tax Law"},
    ],
    "UNH Law": [
        {"value": "ip_law", "label": "Intellectual Property (LL.M.)"},
        {"value": "tech_law", "label": "Commerce and Technology Law (LL.M.)"},
        {"value": "criminal_law", "label": "International Criminal Law and Justice (LL.M.)"},
        {"value": "mctl", "label": "Master's in Commerce and Technology Law"},
        {"value": "miclj", "label": "Master's in International Criminal Law and Justice"},
        {"value": "mip", "label": "Master's in Intellectual Property"},
        {"value": "ip_certificate", "label": "Intellectual Property (Graduate Certificate)"},
        {"value": "tech_law_certificate", "label": "Commerce and Technology (Graduate Certificate)"},
    ],
}

export function get_program_label(programValue, university){
     // Check if the university exists in the dataset
    if (universityPrograms[university]) {
        // Find the program with the matching value
        const program = universityPrograms[university].find(
        (p) => p.value === programValue
        );
        return program ? program.label : programValue; 
    }
    return programValue; 
}