import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../contexts/GlobalContext";
import axios from "axios";
import { Avatar, Chip, IconButton, Tooltip } from "@mui/material";
import DashboardElements from "../NewDashboard/DashboardElements";
import { get_program_label } from "./programData";

const Transcript = () => {
  function separateConversation(conversationText) {
    const rawMessages = conversationText.split(/(?=User:|Agent:)/);
    const separatedMessages = [];
    for (const rawMessage of rawMessages) {
      if (rawMessage.startsWith("User:")) {
        separatedMessages.push({
          speaker: "user",
          message: rawMessage.substring(5).trim(), // "User:" is 5 characters long
        });
      } else if (rawMessage.startsWith("Agent:")) {
        separatedMessages.push({
          speaker: "agent",
          message: rawMessage.substring(6).trim(), // "Agent:" is 6 characters long
        });
      }
    }
    return separatedMessages;
  }

  const [state] = useGlobalContext();
  const [data, setData] = useState();
  const [transcriptData, setTranscriptData] = useState();
  useEffect(() => {
    // if (!state.selectedConversationRetell) return; // Exit early if data is not available

    const apiUrl = `https://api.retellai.com/get-call/${state.selectedConversationRetell.callId}`;
    const bearerToken = process.env.REACT_APP_BEARER_TOKEN;

    const fetchData = async () => {
      try {
        const response = await axios.get(apiUrl, {
          headers: { Authorization: `Bearer ${bearerToken}` },
        });
        setData(response.data);
        setTranscriptData(separateConversation(response.data.transcript));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [state.selectedConversationRetell.callId]); // Ensure useEffect runs when state.selectedConversationRetell changes
  return (
    <>
      <h1 className="font-[Outfit] text-black">
        <span className="font-semibold ">Name: </span>
        {state.selectedConversationRetell && state.selectedConversationRetell.name}
      </h1> 
      <h1 className="font-[Outfit] text-black">
        <span className="font-semibold ">Email: </span>
        {state.selectedConversationRetell && state.selectedConversationRetell.email}
      </h1>
      <h1 className="font-[Outfit] text-black">
        <span className="font-semibold ">Program: </span>
        {state.selectedConversationRetell && get_program_label(state.selectedConversationRetell.program, state.user?.privilege)}
      </h1>
      <h1 className="font-[Outfit] text-black">
        <span className="font-semibold ">Call id: </span>
        {data && data.call_id}
      </h1> 
      <h1 className="font-[Outfit] text-black">
        <span className="font-semibold ">Agent id: </span>
        {data && data.agent_id}
      </h1>
      <h2 className="font-[Outfit] text-black">
        <span className="font-semibold ">Call duration: </span>
        {data && (data.end_timestamp - data.start_timestamp) / 1000} seconds
      </h2>
      <div className="font-[Outfit] font-bold text-black text-xl mt-8">
        Call Transcript
      </div>
      <DashboardElements.StyledAmbassadorContainer
        className="mt-4 overflow-auto"
        style={{
          background: "#000000",
          borderRadius: "10px",
          background: "#FFF",
          border: ".01px solid #e0ebeb",
          padding: "10px 16px",
          boxShadow: "6px 6px 54px 0px rgba(0, 0, 0, 0.05)",
        }}
      >
        {/* {data && data.transcript} */}
        {transcriptData &&
          transcriptData.map((message, index) => (
            <p className="mb-3" key={index}>
              <span className=" font-semibold">{message.speaker}: </span>{" "}
              {message.message}
            </p>
          ))}
      </DashboardElements.StyledAmbassadorContainer>
    </>
  );
};

export default Transcript;
